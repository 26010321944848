interface PermissionLevelProps {
  id: string;
  type: string[];
  level: string[];
}

interface FixedPermissionsProps {
  [key: string]: PermissionLevelProps;
}

interface ArrBtnAccessProps {
  btn: string;
  level: string;
}

export const CheckAccessBtn = async (permissions: string[]) => {
  const arrBtnAccess: ArrBtnAccessProps[] = [];

  const fixedPermissions: FixedPermissionsProps = {
    gestor: {
      id: 'gestor',
      type: ['CLOUD'],
      level: ['GE_N1', 'GE_N2', 'GE_N3', 'GE_N4'],
    },
    simplaz: {
      id: 'simplaz',
      type: [],
      level: [
        'SIMPLAZ',
        'SP_P2',
        'SP_P3',
        'SP_P4',
        'SP_CTB_P5',
        'SP_CTB_P6',
        'SP_CTB_P7',
      ],
    },
    unique: {
      id: 'unique',
      type: [''],
      level: ['UN_N1', 'UN_N2', 'UN_N3', 'UN_N4'],
    },
    analytics: {
      id: 'analytics',
      type: ['ADMIN'],
      level: ['ANALYTICSBI'],
    },
    analyticspro1: {
      id: 'analyticspro1',
      type: ['INTELVENDAS'],
      level: ['GE_N1', 'GE_N2', 'GE_N3', 'GE_L1', 'GE_L2', 'GE_L3'],
    },
    analyticspro2: {
      id: 'analyticspro2',
      type: ['INTELADM'],
      level: [
        'UN_N1',
        'UN_N2',
        'UN_N3',
        'UN_N4',
        'UN_L1',
        'UN_L2',
        'UN_L3',
        'UN_L4',
      ],
    },
    clubedocontador: {
      id: 'clubedocontador',
      type: ['CL_CTD_P1', 'CL_CTD_P2', 'CL_CTD_P3', 'CL_CTD_P4', 'CL_CTD_P5'],
      level: [
        'UN_N1',
        'UN_N2',
        'UN_N3',
        'UN_N4',
        'UN_L1',
        'UN_L2',
        'UN_L3',
        'UN_L4',
      ],
    },
    importafacil: {
      id: 'importafacil',
      type: ['IMPXLS', 'CLOUD'],
      level: ['GE_N1', 'GE_N2', 'GE_N3', 'GE_L1', 'GE_L2', 'GE_L3'],
    },
    quod: {
      id: 'quod',
      type: [],
      level: ['QUOD'],
    },
    file: {
      id: 'file',
      type: [],
      level: [
        'GE_N1',
        'GE_N2',
        'GE_N3',
        'GE_N4',
        'UN_N1',
        'UN_N2',
        'UN_N3',
        'UN_N4',
      ],
    },
    client: {
      id: 'client',
      type: [],
      level: [
        'UN_N1',
        'UN_N2',
        'UN_N3',
        'UN_N4',
        'UN_L1',
        'UN_L2',
        'UN_L3',
        'UN_L4',
      ],
    },
    admin: {
      id: 'admin',
      type: [],
      level: ['ADMIN'],
    },
    payment: {
      id: 'payment',
      type: [],
      level: ['ADMIN'],
    },
    certificate: {
      id: 'certificate',
      type: [],
      level: ['ADMIN'],
    },
  };

  // verifica se existe na session a permissao para btn
  Object.entries(fixedPermissions).forEach(([key, value]) => {
    value.level.forEach((level) => {
      if (key === fixedPermissions.gestor.id) {
        if (
          permissions?.includes(level) === true &&
          permissions?.includes(fixedPermissions.gestor.type[0]) === true
        ) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      } else if (key === fixedPermissions.analytics.id) {
        if (
          permissions?.includes(level) === true &&
          permissions?.includes(fixedPermissions.analytics.type[0]) === true
        ) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      } else if (key === fixedPermissions.analyticspro1.id) {
        if (
          permissions?.includes(level) === true &&
          permissions?.includes(fixedPermissions.analyticspro1.type[0]) === true
        ) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      } else if (key === fixedPermissions.analyticspro2.id) {
        if (
          permissions?.includes(level) === true &&
          permissions?.includes(fixedPermissions.analyticspro2.type[0]) === true
        ) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      } else if (key === fixedPermissions.clubedocontador.id) {
        let hasType = false;
        let hasLevel = null;
        let index = 0;
        while (
          !hasType &&
          index < fixedPermissions.clubedocontador.type.length
        ) {
          const type = fixedPermissions.clubedocontador.type[index];
          if (permissions?.includes(type) === true) {
            hasType = true;
          }
          index++;
        }
        if (hasType) {
          fixedPermissions.clubedocontador.level.forEach((level) => {
            if (permissions?.includes(level)) {
              hasLevel = level;
            }
          });
        }
        if (hasType === true && hasLevel) {
          const existingIndex = arrBtnAccess.findIndex(
            (item) => item.btn === value.id
          );
          if (existingIndex === -1) {
            arrBtnAccess.push({ btn: value.id, level: hasLevel });
          }
        }
      } else if (key === fixedPermissions.importafacil.id) {
        if (
          permissions?.includes(level) === true &&
          (permissions?.includes(fixedPermissions.importafacil.type[0]) ===
            true ||
            permissions?.includes(fixedPermissions.importafacil.type[1]) ===
              true)
        ) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      } else if (
        key === fixedPermissions.quod.id ||
        key === fixedPermissions.simplaz.id ||
        key === fixedPermissions.file.id ||
        key === fixedPermissions.client.id ||
        key === fixedPermissions.admin.id ||
        key === fixedPermissions.payment.id ||
        key === fixedPermissions.certificate.id ||
        key === fixedPermissions.unique.id
      ) {
        if (permissions?.includes(level) === true) {
          arrBtnAccess.push({ btn: value.id, level });
        }
      }
    });
  });
  return arrBtnAccess;
};

